import { Picture, Text } from '@components/ui'
import { useEffect, useMemo, useState } from 'react'
import s from '../../Home.module.css'
import cn from 'classnames'
import { refLink } from '../../utils'
import { Link } from '@components/ui'
import { useGlobal } from '@components/common/GlobalContext'
import Star from '@components/icons/home/Star'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, EffectFade } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

// eslint-disable-next-line no-unused-vars
const Tag = ({ tag }) => {
  return (
    <div className={cn('flex flex-wrap gap-1')}>
      {Array.isArray(tag) ? (
        <>
          {tag.map((item, index) => (
            <span
              className="rounded-full bg-[#c0ebf9] px-[10px] py-1 text-sm font-semibold leading-[1.2] text-brand-color"
              key={index}
            >
              {item}
            </span>
          ))}
        </>
      ) : (
        <span className="rounded-full bg-[#c0ebf9] px-[10px] py-1 text-sm font-semibold leading-[1.2] text-brand-color">
          {tag}
        </span>
      )}
    </div>
  )
}

const Icons = ({ icons }) => {
  return (
    <div className="absolute bottom-3 right-3 flex items-center gap-[6px] min-l:bottom-10 min-l:right-10 min-l:gap-4">
      {icons.map((icon, index) => (
        <div key={index} className="flex items-center gap-[6px]">
          <Picture
            source={icon.icon}
            imgClassName="h-[30px] min-l:h-[80px] !w-auto"
          />
          {icon.label && (
            <span
              className="text-left text-xs font-semibold leading-[1.2] text-[#333]"
              dangerouslySetInnerHTML={{
                __html: icon.label,
              }}
            ></span>
          )}
        </div>
      ))}
    </div>
  )
}

const Review = ({ review, theme }) => {
  return (
    <div
      className={cn(
        'absolute bottom-6 left-0 hidden max-w-[448px] leading-[1.2] min-l:block min-xl:bottom-10',
        theme === 'dark' ? 'text-white' : 'text-[#111]'
      )}
      style={{ color: review.color }}
    >
      <div className="flex items-center gap-3">
        <div className="flex h-9 w-9 items-center justify-center rounded-full bg-brand-sub-color font-bold text-brand-color opacity-75">
          {review.name?.substring(0, 2)}
        </div>
        <div className="flex items-center justify-center gap-[5px]">
          {Array.from({ length: review.stars }).map((item, index) => (
            <Star
              key={index}
              className={cn(
                theme === 'dark' ? 'text-white' : 'text-brand-color'
              )}
            />
          ))}
        </div>
      </div>
      <Text
        html={review.content}
        className="mt-2 text-xs font-semibold !leading-[1.2] opacity-75 min-xxl:mt-6 min-xxl:text-base"
      />
      <Text
        html={review.time}
        className="mt-2 text-sm font-semibold !leading-[1.2] opacity-75 min-xxl:mt-6 min-xxl:text-base"
      />
    </div>
  )
}

const Card = ({
  isMobile,
  section,
  shopCommon,
  isSwiper,
  swiperInit,
  data,
}) => {
  const [video, setVideo] = useState('')
  const [poster, setPoster] = useState('')

  useEffect(() => {
    if (isMobile) {
      setVideo(section.video_mob)
      setPoster(section.image_mob)
    } else {
      setVideo(section.video)
      setPoster(section.image)
    }
  }, [
    isMobile,
    section.image,
    section.image_mob,
    section.video,
    section.video_mob,
  ])

  return (
    <>
      <div className="relative">
        <div
          className={cn('relative', {
            /* 'top-[-47px] mb-[-47px] min-l:top-[-49px] min-l:mb-[-49px]':
              !isSwiper, */
          })}
        >
          <Link
            className=""
            href={
              section.learn_more
                ? refLink(section.learn_more, 'masterbanner_learn')
                : refLink(section.buy_now, 'masterbanner_buynow')
            }
          >
            {section.video ? (
              <video
                src={video}
                poster={poster}
                loop={section.loop}
                autoPlay
                muted
                playsInline
              />
            ) : swiperInit || !isSwiper ? (
              <Picture
                source={`${section.image_xl || section.image}, ${
                  section.image
                } 1920, ${section.image_pad || section.image_mob} 1024, ${
                  section.image_mob
                } 767`}
                alt={section.title}
                imgWidth={2560}
                imgHeight={1130}
                className="h-[650px] w-full min-md:h-[700px] min-l:h-[595px] min-xl:h-[710px] min-xxl:h-[940px]"
                imgClassName="!h-full !w-full object-cover"
              />
            ) : (
              <Picture
                source={`${data[0].image_xl || data[0].image}, ${
                  data[0].image
                } 1920, ${data[0].image_pad || data[0].image_mob} 1024, ${
                  data[0].image_mob
                } 767`}
                alt={data[0].title}
                imgWidth={2560}
                imgHeight={1130}
                className="h-[650px] w-full min-md:h-[700px] min-l:h-[595px] min-xl:h-[710px] min-xxl:h-[940px]"
                imgClassName="!h-full !w-full object-cover"
              />
            )}
          </Link>
          <div className="absolute left-0 top-0 box-border h-full w-full px-8 min-l:px-6 min-xl:px-10">
            <div className="relative mx-auto h-full max-w-[1920px]">
              <Link
                className="absolute left-0 top-0 h-full w-full"
                href={
                  section.learn_more
                    ? refLink(section.learn_more, 'masterbanner_learn')
                    : refLink(section.buy_now, 'masterbanner_buynow')
                }
              ></Link>
              <div
                className={cn(
                  'absolute bottom-[60px] left-0 box-border w-full min-md:bottom-14 min-l:bottom-auto min-l:max-w-[50%]',
                  section.review
                    ? 'min-l:top-[156px] min-xl:top-[235px] min-xxl:top-[256px]'
                    : 'min-l:top-1/2 min-l:-translate-y-1/2',
                  {
                    'bottom-auto top-[88px]': section._mob_position === 'top',
                  }
                )}
              >
                <div className={cn('text-center min-l:text-left')}>
                  {/* {section.tag && <Tag tag={section.tag} />} */}
                  <div
                    className={cn('min-l:max-w-[448px] min-xl:max-w-[524px]')}
                  >
                    {section.title && (
                      <h2
                        className={cn(
                          'text-base font-semibold leading-[1] min-l:text-[20px]',
                          {
                            'text-white': section._theme === 'dark',
                            'l:text-white': section._mob_theme === 'dark',
                            'min-l:text-white': section._pc_theme === 'dark',
                          }
                        )}
                        dangerouslySetInnerHTML={{ __html: section.title }}
                      ></h2>
                    )}
                    {section.subtitle && (
                      <p
                        className={cn(
                          'mt-1 text-[24px] font-bold leading-[1.2] min-l:mt-[18px] min-l:text-[40px] min-l:leading-[1.1] min-xl:text-[40px] min-xxl:text-[48px]',
                          {
                            'text-white': section._theme === 'dark',
                            'l:text-white': section._mob_theme === 'dark',
                            'min-l:text-white': section._pc_theme === 'dark',
                          }
                        )}
                        dangerouslySetInnerHTML={{ __html: section.subtitle }}
                      ></p>
                    )}
                    {section.description && (
                      <p
                        className={cn(
                          'mt-1 text-base font-medium leading-[1.4] min-l:mt-[18px] min-l:leading-[1.1] min-xxl:text-[20px]',
                          {
                            'text-white': section._theme === 'dark',
                            'l:text-white': section._mob_theme === 'dark',
                            'min-l:text-white': section._pc_theme === 'dark',
                          }
                        )}
                        dangerouslySetInnerHTML={{
                          __html: section.description,
                        }}
                      ></p>
                    )}
                  </div>
                  <div className="mt-6 flex justify-center gap-3 min-l:mt-8 min-l:justify-start min-l:gap-4 min-xxl:mt-10">
                    {section.learn_more && (
                      <Link
                        className={cn(
                          !section.buy_now ? s.btnPrimary : s.btnBasic,
                          '!px-[17px] !py-[11px]',
                          {
                            [s.btnWhite]:
                              section._theme === 'dark' ||
                              (section._mob_theme === 'dark' && isMobile) ||
                              (section._pc_theme === 'dark' && !isMobile),
                          }
                        )}
                        href={refLink(section.learn_more, 'masterbanner_learn')}
                      >
                        {section.learn_more_text || shopCommon.learn_more}
                      </Link>
                    )}
                    {section.buy_now && (
                      <Link
                        className={cn(s.btnPrimary, '!px-[17px] !py-[11px]', {
                          [s.btnWhite]:
                            section._theme === 'dark' ||
                            (section._mob_theme === 'dark' && isMobile) ||
                            (section._pc_theme === 'dark' && !isMobile),
                        })}
                        href={refLink(section.buy_now, 'masterbanner_buynow')}
                      >
                        {section.buy_now_text || shopCommon.buyNow}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              {section.icons && <Icons icons={section.icons} />}
              {section.review && (
                <Review review={section.review} theme={section._theme} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const SwiperBanner = ({ section, shopCommon, sectionKey }) => {
  const [swiper, setSwiper] = useState()
  const [swiperInit, setSwiperInit] = useState(false)

  const { setBannerTheme } = useGlobal()

  const [isMobile, setIsMobile] = useState(false)

  const isSwiper = useMemo(() => Array.isArray(section), [section])

  /* useEffect(() => {
    if (isSwiper) {
      setBannerTheme(section[0]._nav_theme || section[0]._theme || 'light')
    } else {
      setBannerTheme(section._nav_theme || section._theme || 'light')
    }
  }, [isSwiper, section, section._nav_theme, section._theme, setBannerTheme])

  const slideChange = (swiper) => {
    const { realIndex } = swiper
    setBannerTheme(
      section[realIndex]._nav_theme || section[realIndex]._theme || 'light'
    )
  } */

  const settings = {
    modules: [Pagination, Autoplay, EffectFade],
    loop: true,
    breakpoints: section.breakpoints || {
      1024: {
        allowTouchMove: false,
      },
    },
    // onSlideChange: slideChange,
    onSwiper: setSwiper,
    onInit: () => {
      setSwiperInit(true)
    },
    pagination: {
      clickable: true,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      delay: section?.[0]?.delay || 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
  }

  if (isSwiper && section.length === 1) {
    settings.loop = false
    settings.autoplay = false
  }

  const PrevArrow = () => {
    return (
      <button
        onClick={() => swiper?.slidePrev()}
        className={cn(
          s.arrow,
          'arrow absolute bottom-4 left-1/2 z-[11] h-5 w-5 opacity-0 transition min-l:h-[30px] min-l:w-[30px] min-xl:bottom-10',
          {
            'ml-[-106px] min-xl:ml-[-130px]': isSwiper && section.length === 2,
            'ml-[-148px] min-xl:ml-[-174px]': isSwiper && section.length === 3,
            'ml-[-180px] min-xl:ml-[-218px]': isSwiper && section.length === 4,
          }
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <circle
            cx="15"
            cy="15"
            r="15"
            transform="matrix(-1 0 0 1 30 0)"
            fill="#EEEEEE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.7501 19.1462C16.4706 19.4513 16.0138 19.4513 15.7343 19.1462L12.0076 15.0791C11.7324 14.7787 11.7324 14.2949 12.0076 13.9945L15.7343 9.92737C16.0138 9.62235 16.4706 9.62235 16.7501 9.92737C17.0253 10.2277 17.0253 10.7116 16.7501 11.0119L13.5203 14.5368L16.7501 18.0617C17.0253 18.362 17.0253 18.8459 16.7501 19.1462Z"
            fill="#6E6E6E"
          />
        </svg>
      </button>
    )
  }

  const NextArrow = () => {
    return (
      <button
        onClick={() => swiper?.slideNext()}
        className={cn(
          s.arrow,
          'arrow absolute bottom-4 left-1/2 z-[11] h-5 w-5 opacity-0 transition min-l:h-[30px] min-l:w-[30px] min-xl:bottom-10',
          {
            'ml-[77px] min-xl:ml-[100px]': isSwiper && section.length === 2,
            'ml-[114px] min-xl:ml-[145px]': isSwiper && section.length === 3,
            'ml-[148px] min-xl:ml-[188px]': isSwiper && section.length === 4,
          }
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <circle cx="15" cy="15" r="15" fill="#EEEEEE" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.2499 19.1462C13.5294 19.4513 13.9862 19.4513 14.2657 19.1462L17.9924 15.0791C18.2676 14.7787 18.2676 14.2949 17.9924 13.9945L14.2657 9.92737C13.9862 9.62235 13.5294 9.62235 13.2499 9.92737C12.9747 10.2277 12.9747 10.7116 13.2499 11.0119L16.4797 14.5368L13.2499 18.0617C12.9747 18.362 12.9747 18.8459 13.2499 19.1462Z"
            fill="#6E6E6E"
          />
        </svg>
      </button>
    )
  }

  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth < 1024) return setIsMobile(true)
    setIsMobile(false)
  }
  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  return (
    <div
      id={sectionKey}
      className={cn({
        hidden: section.hidden || section?.[0]?.hidden,
      })}
      style={{ marginTop: section.marginTop || section?.[0]?.marginTop }}
    >
      {isSwiper ? (
        <Swiper
          {...settings}
          className={cn(
            s.swiperBanner
            /* sectionKey === 'v4SwiperBanner'
              ? 'top-[-47px] mb-[-47px] min-l:top-[-49px] min-l:mb-[-49px]'
              : 'top-[-1px]' */
          )}
        >
          {section.map((item, index) => (
            <SwiperSlide key={index}>
              <Card
                isMobile={isMobile}
                section={item}
                shopCommon={shopCommon}
                isSwiper={isSwiper}
                swiperInit={swiperInit}
                data={section}
              />
            </SwiperSlide>
          ))}
          {section.length > 1 && (
            <>
              <PrevArrow />
              <NextArrow />
            </>
          )}
        </Swiper>
      ) : (
        <Card
          isMobile={isMobile}
          section={section}
          shopCommon={shopCommon}
          isSwiper={isSwiper}
        />
      )}
    </div>
  )
}

export default SwiperBanner
